import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import useUser from "./hooks/useUser";

const SignupPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const {setUser} = useUser();
  const navigate = useNavigate();

  // Handle form submission
  const handleSignup = async () => {
    // Form validation (optional, add your own checks here)
    if (!name || !email || !password) {
      toast.error("Please fill out all fields");
      return;
    }

    // Construct the data object
    const userData = {
      name,
      email,
      password,
    };

    // Make the API call
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      const data = await response.json();

      if (response.ok) {
        // Successful signup, navigate to another page
        setUser(data.user);
        localStorage.setItem("token", data.token);
        navigate("/");
      } else {
        // Handle API errors
        toast.error(data.message || "Signup failed");
      }
    } catch (error) {
      console.error("Error during signup:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50">
      <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-md">
      <div className="flex justify-center items-center w-full">
          <img
            src="/logo.jpg"
            class="w-24 h-24 rounded-full object-cover"
            alt="Logo"
          />
        </div>
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-4">
          Join Us Today!
        </h2>
        <p className="text-sm text-gray-600 text-center mb-6">
          Start your <span className="font-semibold">7-day free trial</span> and experience the best we offer.
        </p>

        <div>
          <div className="mb-4">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
              Full Name
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your name"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your email"
            />
          </div>

          <div className="mb-6">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Create a password"
            />
          </div>

          <button
            onClick={handleSignup}
            disabled={loading}
            className={`w-full py-2 text-white ${loading ? 'bg-gray-500' : 'bg-gray-700'} rounded-lg hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-600`}
          >
            {loading ? "Signing Up..." : "Sign Up"}
          </button>
        </div>

        <p className="text-sm text-gray-600 text-center mt-4">
          Already have an account?{" "}
          <a href="/login" className="text-gray-800 hover:underline">
            Log in here
          </a>
        </p>
      </div>
    </div>
  );
};

export default SignupPage;
