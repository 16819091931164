import React, { useState } from "react";
import PaymentPage from "./Payment";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Link } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const SubscriptionPage = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedPlan(null);
  };

  return (
    <>
      <button
        onClick={() =>
          handleSelectPlan({
            title: "Standard Plan",
            name: process.env.CHARGEBEE_PLAN || "standard_plan-USD-Monthly",
            amount: 25,
          })
        }
        className="px-3 py-2 text-white rounded hover:bg-blue-800 bg-blue-600"
      >
        Pricing
      </button>

      <a
        className="px-3 py-2 text-white rounded hover:bg-green-800 mx-2 bg-green-600"
        target="_blank"
        rel="noreferrer"
        href={process.env.REACT_APP_SELF_SERVICE_PORTAL}
      >
        Open Self Service Portal
      </a>

      {isModalOpen && selectedPlan && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="relative w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
            <button
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
              onClick={handleCloseModal}
            >
              ✕
            </button>

            <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
              Subscribe to Our Plan
            </h2>

            <div className="p-6 bg-gray-100 rounded-lg shadow-sm">
              <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                {selectedPlan.title}
              </h3>
              {/* <p className="text-sm text-gray-600 mb-4">
                Perfect for small teams to get started
              </p> */}
              <span className="text-xl font-bold text-gray-800">
                ${selectedPlan.amount}/month
              </span>
            </div>

            <div className="mt-6">
              <Elements stripe={stripePromise}>
                <PaymentPage plan={selectedPlan} onClose={handleCloseModal} />
              </Elements>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SubscriptionPage;
