import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NBAData from "./NBAData";
import LoginPage from "./Login";
import SignupPage from "./Signup";
// import SiteUnavailable from "./pages/SiteUnavailable";

export default function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<SiteUnavailable />} /> */}
        <Route path="/" element={<NBAData />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
      </Routes>
    </Router>
  );
}
