import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import axios from "axios";
import toast from "react-hot-toast";
import useUser from "./hooks/useUser";

const PaymentPage = ({ plan, onClose }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentStatus, setPaymentStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const {user} = useUser();

  const handlePayment = async () => {
    setLoading(true);
    try {

    if (!stripe || !elements) {
      setPaymentStatus("Stripe has not loaded yet. Please try again.");
      return;
    }
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/subscription/api/signup`,
      {name: user.name, email: user.email, id: user._id},
      {
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, },
      }
    );

    if(response.data){
      localStorage.setItem("customer_id", response.data.stripe_id.id);
      localStorage.setItem("chargebee_id", response.data.chargebee_id);
      localStorage.setItem("email", response.data.customer_info.email);
      localStorage.setItem("name", response.data.customer_info.first_name);
    }
    else{
      toast.error("payment failed");
      return;
    }

    const card = elements.getElement(CardElement);
    if (!card) {
      setPaymentStatus("Card details are not available. Please try again.");
      return;
    }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card,
      });

      if (error) {
        setPaymentStatus("Payment failed: " + error?.message);
        setLoading(false);
        return;
      }

      const paymentResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/subscription/api/subscription`,
        {
          plan_id: plan.name,
          amount: plan.amount,
          customer: {
            email: localStorage.getItem("email"),
            first_name: localStorage.getItem("first_name"),
            last_name: localStorage.getItem("last_name"),
            stripe_customer_id: localStorage.getItem("customer_id"),
            chargebee_id: localStorage.getItem("chargebee_id"),
          },
          paymentMethodId: paymentMethod.id,
        },
        {
          headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, },
        }
      );

      setPaymentStatus("Subscription successful");
      toast.success("Payment Successful");
    } catch (error) {
      setPaymentStatus(
        "Payment failed"
      );
    }
    finally{
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
      <div className="max-w-[450px] w-full p-6 bg-white rounded-lg shadow-lg">
      

        <h2 className="text-2xl font-bold text-center mb-6 text-gray-800">
          Complete Your Payment
        </h2>
        <h3 className="text-xl font-semibold text-center text-gray-700 mb-4">
          Plan: {plan.title}
        </h3>
        <div className="mb-4">
          <label
            htmlFor="card-element"
            className="block text-lg font-semibold text-gray-700 mb-2"
          >
            Card Details
          </label>
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#32325d",
                  letterSpacing: "0.025em",
                  padding: "10px",
                  backgroundColor: "#f7fafc",
                  borderRadius: "4px",
                  border: "1px solid #e2e8f0",
                  ":focus": {
                    borderColor: "#63b3ed",
                    boxShadow: "0 0 0 1px #63b3ed",
                  },
                },
              },
            }}
          />
        </div>

        {loading ? (
          <div className="flex justify-center items-center mb-4">
            <svg
              className="animate-spin h-6 w-6 text-blue-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <circle
                cx="12"
                cy="12"
                r="10"
                strokeWidth="4"
                stroke="#e5e7eb"
                fill="none"
              ></circle>
              <path
                fill="currentColor"
                d="M4 12a8 8 0 018-8V4a10 10 0 00-10 10h2z"
              ></path>
            </svg>
          </div>
        ) : (
          <button
            onClick={handlePayment}
            disabled={!stripe || !elements || loading}
            className="w-full py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            Pay ${plan.amount}
          </button>
        )}

        {paymentStatus && (
          <p className="mt-4 text-center text-lg text-gray-700 break-words">
            {paymentStatus}
          </p>
        )}

        <div className="w-full flex justify-center items-start">
          <button
            onClick={onClose}
            className="mt-4 text-center text-blue-600 font-semibold hover:underline"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
