import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useUser from "./hooks/useUser";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { setUser } = useUser();
  const navigate = useNavigate();

  // Handle form submission
  const handleLogin = async () => {
    // Form validation (optional, add your own checks here)
    if (!email || !password) {
      toast.error("Please fill out all fields");
      return;
    }

    // Construct the data object
    const loginData = {
      email,
      password,
    };

    // Make the API call for login
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/user/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(loginData),
        }
      );

      const data = await response.json();

      if (response.ok) {
        // Successfully logged in, navigate to the home page or dashboard
        setUser(data.user);
        localStorage.setItem("token", data.token);
        navigate("/");
      } else {
        // Handle login failure (e.g., incorrect credentials)
        toast.error(data.message || "Login failed");
      }
    } catch (error) {
      console.error("Error during login:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50">
      <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-md">
        <div className="flex justify-center items-center w-full">
          <img
            src="/logo.jpg"
            class="w-24 h-24 rounded-full object-cover"
            alt="Logo"
          />
        </div>
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
          Welcome Back!
        </h2>
        <p className="text-sm text-gray-600 text-center mb-4">
          Sign in to continue to your account.
        </p>

        {/* Form Section */}
        <div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Email Address
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your email"
            />
          </div>

          <div className="mb-6">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your password"
            />
          </div>

          <button
            onClick={handleLogin}
            disabled={loading}
            className={`w-full py-2 text-white ${
              loading ? "bg-gray-500" : "bg-gray-700"
            } rounded-lg hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-600`}
          >
            {loading ? "Logging In..." : "Login"}
          </button>
        </div>

        <p className="text-sm text-gray-600 text-center mt-4">
          Don't have an account?{" "}
          <a href="/signup" className="text-gray-800 hover:underline">
            Sign up here
          </a>
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
