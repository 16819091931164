import { create } from "zustand";
import { persist } from "zustand/middleware";

const initialUserState = {
  _id: "",
  name: "",
  email: "",
};

const useUser = create(
  persist(
    (set, get) => ({
      user: { ...initialUserState },
      setId: (_id) => set((state) => ({ user: { ...state.user, _id } })),
      setName: (name) => set((state) => ({ user: { ...state.user, name } })),
      setEmail: (email) => set((state) => ({ user: { ...state.user, email } })),
      setUser: (userData) => set({ user: userData }),
      resetUser: () => set({ user: { ...initialUserState } }),
    }),
    {
      name: "user-storage",
      getStorage: () => localStorage, // Use localStorage
    }
  )
);

export default useUser;
